import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AffairApiService from 'api/AffairApiService';
import UserApiService from 'api/UserApiService';
import InputDate from 'components/InputDate';
import AutocompleteCustom, { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import SelectUser from 'components/SelectUser';
import useManagementHelper from 'features/Management/services/useManagementHelper';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Affair from 'types/entities/Affair';
import Order from 'types/entities/Order';
import User from 'types/entities/User';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './OrderEditDrawer.scss';

const OrderEditDrawer = () => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const drawerState = useSelector((state) => state.drawer);
  const { order, handleUpdateOrder, isPartial = false } = drawerState.data;
  const [clients, setClients] = useState<User[]>([]);
  const [affairs, setAffairs] = useState<User[]>([]);
  const [t] = useTranslation();
  const { isPathForProduction } = useManagementHelper();
  const isUpdateOrderLoading = useSelector((state) => state.loadings.updateOrderDrawerEdit) ?? 0;
  const [orderEdited, setOrderEdited] = useState<Order>(order);
  const { makeCall } = useApi();
  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  useEffect(() => {
    const getAffairs = async () => {
      if (appState.customer) {
        const res = await makeCall(
          AffairApiService.fetchByCustomerId(appState.customer?.id),
          'Error while loading affairs'
        );
        setAffairs(res);
      }
    };
    const getClients = async () => {
      if (appState.customer) {
        const res = await makeCall(
          UserApiService.getClientsOnCustomer(appState.customer?.id, { is_archived: 0, size: -1 }),
          'Error while loading clients'
        );
        setClients(res);
      }
    };
    getAffairs();
    getClients();
  }, [appState.customer]);

  const handleSubmit = async () => {
    await handleUpdateOrder(orderEdited);
    dispatch(closeDrawerAction());
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    if (e.currentTarget.name === 'draft_reference' && e.currentTarget.value === '') {
      return setOrderEdited({ ...orderEdited, [e.currentTarget.name]: null });
    }
    return setOrderEdited({ ...orderEdited, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleSelectClient = (client: User | null | undefined) => {
    if (client) return setOrderEdited({ ...orderEdited, client, client_id: client.id });
    return setOrderEdited({ ...orderEdited, client: null, client_id: null });
  };
  const handleSelectDelegue = (delegate: User | null | undefined) => {
    if (delegate) return setOrderEdited({ ...orderEdited, delegue: delegate, delegue_id: delegate.id });
    return setOrderEdited({ ...orderEdited, delegue: null, delegue_id: null });
  };
  const handleSelectOptions = (e: SelectTypeOptions | null, property: PropertyNameOptions) => {
    if (e && property === 'affair' && objectIsAffair(e)) {
      return setOrderEdited({
        ...orderEdited,
        [`${property}`]: e,
        [`${property}_name`]: e.name,
        [`${property}_otp`]: e.otp,
        [`${property}_id`]: e.id,
      });
    }
    return setOrderEdited({ ...orderEdited, affair: null, affair_id: null });
  };
  const handleDateChange = (name: string, date: Moment | null) => {
    // setOrderEdited({ ...orderEdited, [name]: date?.startOf('day').format('YYYY-MM-DD') });
    //  setOrderEdited({ ...orderEdited, [name]: moment(date).format('YYYY-MM-DD') });
    setOrderEdited({
      ...orderEdited,
      [name]: date ? moment(date).format('YYYY-MM-DD') : null, // Handle null for reset
    });
  };

  const handleClientConnectionSwitch = (value: boolean) => {
    setOrderEdited(() => ({ ...orderEdited, client_connection: value }));
  };

  // const handleSwitchSign = (value: boolean) => {
  //   setOrderEdited(() => ({ ...orderEdited, is_sign_mandatory: value }));
  // };

  const [possiblesCreators, setPossiblesCreators] = useState<User[]>([]);

  useEffect(() => {
    const getPossibleUsersCreators = async () => {
      if (userRoles.isOrderManager) {
        const creators = await UserApiService.fetchPossiblesDelegues();
        setPossiblesCreators(creators);
      }
    };
    getPossibleUsersCreators();
  }, [appState.roles]);

  return (
    <>
      {!isPathForProduction() && (
        <TextField
          label={t('Draft_reference')}
          name="draft_reference"
          id="Draft_reference"
          sx={{ mb: 3 }}
          fullWidth
          placeholder={t('Draft_reference_text')}
          onChange={handleChange}
          value={orderEdited?.draft_reference ?? ''}
        />
      )}
      <TextField
        label={t('Name_spec')}
        name="name_spec"
        id="name_spec"
        sx={{ mb: 3 }}
        fullWidth
        placeholder={t('Name_spec')}
        onChange={handleChange}
        value={orderEdited?.name_spec}
        required
      />
      <TextField
        label={t('Ref_spec')}
        name="ref_spec"
        id="ref_spec"
        sx={{ mb: 3 }}
        fullWidth
        className="input-section"
        placeholder={t('Ref_spec')}
        onChange={handleChange}
        value={orderEdited?.ref_spec}
        required
      />

      {!isPartial && (
        <Box sx={{ mb: 2 }}>
          <InputDate
            label={`${t('Start_date')} *`}
            name="start_date"
            id="start_date"
            placeholder={dateFormat}
            onchange={handleDateChange}
            value={orderEdited?.start_date}
            required
          />
        </Box>
      )}
      {!isPartial && (
        <Box sx={{ mb: 2 }}>
          <InputDate
            label={`${t('End_date')} *`}
            name="end_date"
            id="end_date"
            placeholder={dateFormat}
            onchange={handleDateChange}
            value={orderEdited?.end_date}
            required
            min={order?.start_date}
          />
        </Box>
      )}
      {affairs && (
        <>
          <Box sx={{ mb: 2 }}>
            <AutocompleteCustom
              label={t('Choose_by_name')}
              options={affairs}
              optionsLabels="name"
              handleSelectOptions={handleSelectOptions}
              selectedOption={orderEdited?.affair}
              propertyName="affair"
              required={isPathForProduction()}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <AutocompleteCustom
              label={t('Choose_by_OTP_code')}
              options={affairs}
              optionsLabels="otp"
              handleSelectOptions={handleSelectOptions}
              selectedOption={orderEdited?.affair}
              propertyName="affair"
              required={isPathForProduction()}
            />
          </Box>
        </>
      )}
      {userRoles.isOrderManager && (
        <Box sx={{ mb: 2 }}>
          <SelectUser
            users={possiblesCreators}
            selectedUser={orderEdited?.delegue}
            onSelectUser={handleSelectDelegue}
            label={t('Representative')}
          />
        </Box>
      )}
      {clients && (
        <Box sx={{ mb: 2 }}>
          <SelectUser
            users={clients}
            selectedUser={orderEdited?.client}
            onSelectUser={handleSelectClient}
            label={t('Client')}
            required={isPathForProduction()}
          />
        </Box>
      )}
      <Box sx={{ mb: 5 }}>
        <Grid item xs={12} lg={6}>
          <Typography color="neutral" sx={{ mb: 1, fontWeight: 500 }}>
            {t('client_connection_platform')}
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              sx={{ minWidth: 100 }}
              onClick={() => handleClientConnectionSwitch(true)}
              variant={orderEdited.client_connection ? 'contained' : 'outlined'}
              startIcon={orderEdited.client_connection ? <CheckCircleIcon /> : null}
            >
              {t('yes')}
            </Button>
            <Button
              sx={{ minWidth: 100 }}
              onClick={() => handleClientConnectionSwitch(false)}
              variant={!orderEdited.client_connection ? 'contained' : 'outlined'}
              startIcon={!orderEdited.client_connection ? <CheckCircleIcon /> : null}
            >
              {t('no')}
            </Button>
          </Stack>
        </Grid>
      </Box>
      <Box justifyContent="center" display="flex">
        {userRoles.isOrderManager && (
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isUpdateOrderLoading !== 0}
            startIcon={<Add />}
            color="success"
            disabled={
              !orderEdited.name_spec ||
              !orderEdited.ref_spec ||
              !orderEdited.start_date ||
              !orderEdited.end_date ||
              (!orderEdited.affair && isPathForProduction()) ||
              (!orderEdited.client && isPathForProduction())
            }
          >
            {t('Validate')}
          </LoadingButton>
        )}
      </Box>
    </>
  );
};
export default OrderEditDrawer;

const objectIsAffair = (obj: SelectTypeOptions): obj is Affair => {
  if (obj && Object.keys(obj).includes('otp')) {
    return true;
  }
  return false;
};
