import { RestartAlt } from '@mui/icons-material';
import MomentUtils from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import moment, { Moment, MomentInput } from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from './CustomIcons';

type InputLabelProps = {
  label: ReactNode;
  name: string;
  id: string;
  placeholder: string;
  onchange: (name: string, date: Moment | null) => void | null;
  required?: boolean;
  value: string | null | undefined;
  min?: string | null;
  max?: string | null;
  classNameLabel?: string;
  disabled?: boolean;
  canEmpty?: boolean;
  showError?: boolean;
  variant?: any;
  reset?: Moment | null;
};
const InputDate = (props: InputLabelProps) => {
  const {
    label,
    name,
    id,
    placeholder,
    onchange,
    required = false,
    value,
    min,
    max,
    disabled = false,
    canEmpty = false,
    showError = false,
    variant,
    reset,
  } = props;
  const [t] = useTranslation();
  // const handleReset = () => {
  //   if (reset && moment(reset).isValid()) {
  //     const formattedReset: Moment = moment(reset);
  //     onchange(name, formattedReset);
  //   } else onchange(name, null);
  // };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [localValue, setLocalValue] = useState<string | null | undefined>(value);

  const [isError, setIsError] = useState(false);
  const [textErrorHelper, setTextErrorHelper] = useState<string | null>(null);

  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  useEffect(() => {
    setLocalValue(value || null);
  }, [value]);

  const normalizeDate = (dateStr: MomentInput, format: string): string | null => {
    if (!dateStr) return null;

    // Try strict parsing with the expected format
    const strictParsed = moment(dateStr, format, true);
    if (strictParsed.isValid()) {
      console.log(`Strict Parsed: ${strictParsed.format(format)}`);
      return strictParsed.format(format);
    }

    // Fallback: Attempt to parse common formats
    const formats = ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD'];
    const fallbackParsed = formats.find((fmt) => moment(dateStr, fmt, true).isValid());

    if (fallbackParsed) {
      const parsedMoment = moment(dateStr, fallbackParsed, true);
      console.log(`Fallback Parsed (${fallbackParsed}): ${parsedMoment.format(format)}`);
      return parsedMoment.format(format);
    }

    console.log(`Failed to parse date: ${dateStr}`);
    return null;
  };

  useEffect(() => {
    console.log('Input Values:', { localValue, min, max });

    if (!localValue) {
      setTextErrorHelper('');
      setIsError(false);
      return;
    }

    const normalizedLocalValue = normalizeDate(localValue, dateFormat);
    const normalizedMinDate = min ? normalizeDate(min, dateFormat) : null;
    const normalizedMaxDate = max ? normalizeDate(max, dateFormat) : null;

    console.log('Normalized Dates:', {
      normalizedLocalValue,
      normalizedMinDate,
      normalizedMaxDate,
    });

    const parsedLocalDate = normalizedLocalValue ? moment(normalizedLocalValue, dateFormat, true) : null;
    const parsedMinDate = normalizedMinDate ? moment(normalizedMinDate, dateFormat, true) : null;
    const parsedMaxDate = normalizedMaxDate ? moment(normalizedMaxDate, dateFormat, true) : null;

    console.log('Parsed Dates:', {
      parsedLocalDate: parsedLocalDate?.format('YYYY-MM-DD') || 'Invalid',
      parsedMinDate: parsedMinDate?.format('YYYY-MM-DD') || 'Invalid',
      parsedMaxDate: parsedMaxDate?.format('YYYY-MM-DD') || 'Invalid',
    });

    if (!parsedLocalDate?.isValid() && showError) {
      setTextErrorHelper(t('Date_invalid'));
      setIsError(true);
      return;
    }

    if (parsedMinDate?.isValid() && parsedLocalDate) {
      const isSameOrAfter = parsedLocalDate.isSameOrAfter(parsedMinDate, 'day');
      if (!isSameOrAfter) {
        setTextErrorHelper(`${t('Date_should_be_superior_to')} ${parsedMinDate.format(dateFormat)}`);
        setIsError(true);
        return;
      }
    }

    if (parsedMaxDate?.isValid() && parsedLocalDate) {
      const isSameOrBefore = parsedLocalDate.isSameOrBefore(parsedMaxDate, 'day');
      if (!isSameOrBefore) {
        setTextErrorHelper(`${t('Date_should_be_inferior_to')} ${parsedMaxDate.format(dateFormat)}`);
        setIsError(true);
        return;
      }
    }

    setTextErrorHelper(null);
    setIsError(false);
  }, [min, max, localValue, dateFormat, canEmpty, value, showError, t]);

  const handleReset = () => {
    console.log('Reset clicked', { name });

    // Use a controlled reset
    const resetValue = null;
    setLocalValue(resetValue);
    onchange(name, resetValue);
    setOpen(false);
  };

  const onChangeHandler = (name: string, date: Moment) => {
    // Check if date is valid
    if (date && moment.isMoment(date) && date.isValid() && !isError) {
      const formattedDate = date.format(dateFormat);
      setLocalValue(formattedDate);
      onchange(name, date);
    } else {
      setLocalValue(null);
      onchange(name, null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <DatePicker
        value={value || null}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disabled={disabled}
        minDate={min ? moment(min) : null}
        maxDate={max ? moment(max) : null}
        inputFormat={dateFormat}
        toolbarPlaceholder={placeholder}
        className="datepicker-custom"
        onChange={(date: unknown) => onChangeHandler(name, date as unknown as Moment)}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            disabled={disabled}
            required={required}
            error={isError}
            color={required ? 'primary' : undefined}
            helperText={textErrorHelper}
            fullWidth
            variant={variant}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleReset} size="small">
                    <RestartAlt />
                  </IconButton>
                  <IconButton edge="end" onClick={handleOpen} size="small" color="primary">
                    <CalendarIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
export default InputDate;
