import { Box, Button, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import InputDate from 'components/InputDate';
import AutocompleteCustom, { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import SelectUser from 'components/SelectUser';
import moment, { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import Order from 'types/entities/Order';
import User from 'types/entities/User';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type OrderFormProps = {
  handleChange: (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  handleDateChange: (name: string, date: Moment | null) => void;
  handleSelectOptions: (e: SelectTypeOptions | null, property: PropertyNameOptions) => void;
  handleSelectClient: (client: User | null | undefined) => void;
  handleSelectRepresentative: (user: User | null | undefined) => void;
  handleSwitchSign: (value: boolean) => void;
  handleClientConnectionSwitch: (value: boolean) => void;
  order: Partial<Order> | undefined;
  creators: User[];
  clients: User[];
};
const OrderForm = (props: OrderFormProps) => {
  const {
    order,
    handleChange,
    handleDateChange,
    handleSelectOptions,
    handleSelectClient,
    // handleSwitchSign,
    handleClientConnectionSwitch,
    creators,
    handleSelectRepresentative,
    clients,
  } = props;

  const [t] = useTranslation();
  const appState = useSelector((state) => state.app);

  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  const formatDate = (date: string | number | Date | Moment | null | undefined): string => {
    if (!date) return '';

    return moment(date).format('YYYY-MM-DD');
  };

  return (
    <Box sx={{ mb: 5 }}>
      <Typography sx={{ mb: 3 }}>{t('Enter_affair_parameters')}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor="draft_reference">{t('Draft_reference_text')}</InputLabel>
          <TextField
            id="draft_reference"
            name="draft_reference"
            value={order?.draft_reference ?? ''}
            onChange={handleChange}
            placeholder={t('Draft_reference')}
            fullWidth
            variant="filled"
          />
        </Grid>
        {clients && (
          <Grid item xs={12} lg={6}>
            <SelectUser
              users={creators}
              id="representative"
              selectedUser={order?.delegue}
              onSelectUser={handleSelectRepresentative}
              label={t('Representative')}
              variant="filled"
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor="name_spec">
            {t('Name_spec')}{' '}
            <Typography display="inline" color="error">
              *
            </Typography>
          </InputLabel>
          <TextField
            id="draft_reference"
            name="name_spec"
            value={order?.name_spec}
            onChange={handleChange}
            placeholder={t('Name_spec')}
            fullWidth
            variant="filled"
            required
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputLabel htmlFor="ref_spec">
            {t('Ref_spec')}{' '}
            <Typography display="inline" color="error">
              *
            </Typography>
          </InputLabel>
          <TextField
            id="draft_reference"
            name="ref_spec"
            value={order?.ref_spec}
            onChange={handleChange}
            placeholder={t('Ref_spec')}
            fullWidth
            variant="filled"
            required
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <InputDate
            label={t('Start_date')}
            name="start_date"
            id="start_date"
            placeholder={dateFormat}
            onchange={handleDateChange}
            value={formatDate(order?.start_date)}
            required
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <InputDate
            label={t('End_date')}
            name="end_date"
            id="end_date"
            placeholder={dateFormat}
            onchange={handleDateChange}
            value={formatDate(order?.end_date)}
            required
            //  min={order?.start_date}
            min={formatDate(order?.start_date) || moment().format('YYYY-MM-DD')}
            variant="filled"
            showError
          />
        </Grid>
        {clients && (
          <Grid item xs={12} lg={6}>
            <SelectUser
              users={clients}
              selectedUser={order?.client}
              onSelectUser={handleSelectClient}
              label={t('Client')}
              variant="filled"
            />
          </Grid>
        )}
        {appState.affairs && (
          <>
            <Grid item xs={12} lg={6}>
              <AutocompleteCustom
                label={t('Choose_by_name')}
                options={appState.affairs}
                optionsLabels="name"
                handleSelectOptions={handleSelectOptions}
                selectedOption={order?.affair}
                propertyName="affair"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <AutocompleteCustom
                label={t('Choose_by_OTP_code')}
                options={appState.affairs}
                optionsLabels="otp"
                handleSelectOptions={handleSelectOptions}
                selectedOption={order?.affair}
                propertyName="affair"
                variant="filled"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={6}>
          <Typography color="neutral" sx={{ mb: 1, fontWeight: 500 }}>
            {t('client_connection_platform')}
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              sx={{ minWidth: 100 }}
              onClick={() => handleClientConnectionSwitch(true)}
              variant={order && order.client_connection ? 'contained' : 'outlined'}
              startIcon={order && order.client_connection ? <CheckCircleIcon /> : null}
            >
              {t('yes')}
            </Button>
            <Button
              sx={{ minWidth: 100 }}
              onClick={() => handleClientConnectionSwitch(false)}
              variant={order && !order.client_connection ? 'contained' : 'outlined'}
              startIcon={order && !order.client_connection ? <CheckCircleIcon /> : null}
            >
              {t('no')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
export default OrderForm;
