import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Box } from '@mui/material';
import { remove } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cancelSelectByHand } from 'store/actions/deliverableActions';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';

export const DeliverableConfirmationDialog: React.FC = () => {
  const [t] = useTranslation();
  const { state, dispatch } = useAppState();
  const open = useSelector((state) => state.dialogs.deliverableConfirmationDialog);
  const dialogData = useSelector((state) => state.dialogs.data);
  const [cancelReason, setCancelReason] = useState<string | null>(null);
  const loadings = useSelector((state) => state.loadings);

  const handleClose = () => {
    setCancelReason(null);
    dispatch(closeDialogAction('deliverableConfirmationDialog'));
  };

  const handleConfirm = () => {
    if (dialogData?.confirm) {
      if (shouldDisplayCancelReasonInput) {
        dialogData.confirm(cancelReason);
      } else {
        dialogData.confirm();
      }
    }
    handleClose();
  };

  if (!dialogData) {
    return null; // Or you can return an empty Dialog with a loading state if needed
  }

  const shouldDisplayCancelReasonInput = dialogData.title === 'Cancel Deliverables';

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t(dialogData.title)}</DialogTitle>
        <DialogContent>
          <Typography>{t(dialogData.message)}</Typography>
          {shouldDisplayCancelReasonInput && (
            <Box mt={2}>
              <Typography>{t('Reason for cancellation')}</Typography>
              <TextField
                id="cancel-reason-input"
                name="cancel-reason"
                onChange={(e) => setCancelReason(e.target.value)}
                placeholder={t('Enter your text here')}
                value={cancelReason ?? ''}
                required
                rows={4}
                multiline
                fullWidth
                disabled={!!loadings.api}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={shouldDisplayCancelReasonInput && !cancelReason?.trim()}
          >
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeliverableConfirmationDialog;
