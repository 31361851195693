import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import UserApiService from 'api/UserApiService';
import { Loader } from 'components';
import { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import useApi from 'hooks/useApi';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Order from 'types/entities/Order';
import TMOrderScope from 'types/entities/TMOrderScope';
import User from 'types/entities/User';
import Scope from 'types/entities/Scope';
import TimeMaterialOrderForm from '../TnMOrderForm/TnMOrderForm';
import ScopeApiService from 'api/ScopeApiService';
import { updateIds } from 'helpers/orderhelper';
import { closeDialogAction, openDialogAction } from 'store/actions/dialogsActions';
import { Add, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import OrderActionRestrictionDialog from '../OrderDialogs/OrderActionRestrictionDialog';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { OrderStatus } from 'types/enums/OrderStatus';

type ActionType = 'save_as_draft' | 'validate';

const TnMEditionOrder = () => {
  const editMode = true;
  const { dispatch } = useAppState();
  const { management } = useSelector((state) => state);
  const open = useSelector((state) => state.dialogs.timeMaterialEditionOrder);
  const dialogData = useSelector((state) => state.dialogs);

  const isUpdateOrderLoading = useSelector((state) => state.loadings.updateTimeMaterialOrderEdit) ?? 0;

  const appState = useSelector((state) => state.app);
  const [t] = useTranslation();

  const history = useHistory();
  const { makeCall } = useApi();
  const customer = useSelector((state) => state.app.customer);

  const { order, handleTMUpdateOrder, isPartial = false } = dialogData.data;

  const initialDialogData = {
    data: {
      handleTMUpdateOrder, // Or set this to an actual function if you want a default
    },
  };
  const [initialDiaData, setInitialDiaData] = useState(initialDialogData);
  const [editOrder, setEditOrder] = useState<Order>(order);
  // console.log('editOrder:', editOrder);

  const [isInValidation, setIsInValidation] = useState(false);
  const [isMissingInputs, setIsMissingInputs] = useState(false);
  const [isDraftMissingInputs, setIsDraftMissingInputs] = useState(false);
  const [creators, setCreators] = useState<User[]>([]);
  const [scopes, setScopes] = useState<Scope[]>([]);
  const [selectedScopes, setSelectedScopes] = useState<Scope[]>([]);
  const [archivedScopes, setArchivedScopes] = useState<Scope[]>([]);
  const [clients, setClients] = useState<User[]>([]);
  const [consultants, setConsultants] = useState<User[]>([]);
  const [deliveryManagers, setDeliveryManagers] = useState<User[]>([]);
  const [archivedDeliveryManagers, setArchivedDeliveryManagers] = useState<User[]>([]);
  const [archivedConsultants, setArchivedConsultants] = useState<User[]>([]);
  const [selectedConsultants, setSelectedConsultants] = useState<User[]>([]);
  const [selectedDeliveryManager, setSelectedDeliveryManager] = useState<User[]>([]);
  const [availableScopes, setAvailableScopes] = useState<Scope[]>([]);
  const [availableConsultants, setAvailableConsultants] = useState<User[]>([]);
  const [availableDeliveryManagers, setAvailableDeliveryManagers] = useState<User[]>([]);

  useEffect(() => {
    if (order) {
      setEditOrder({
        ...order,
        start_date: order.start_date || moment().toISOString(),
        end_date: order.end_date || moment().toISOString(),
        is_sign_mandatory: order.is_sign_mandatory ?? true,
        client_connection: order.client_connection,
      });
      setSelectedScopes(order.tmscopes || []);
      setSelectedConsultants(order.tmconsultants || []);
      setSelectedDeliveryManager(order.tmaccountables || []);
      setAvailableScopes(order.scopes || []);
      setAvailableConsultants(order.consultants || []);
      setAvailableDeliveryManagers(order.delivery_managers || []);
    }
  }, [order, appState]);

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    return setEditOrder((prev) => ({ ...prev, [e.currentTarget.name]: e.currentTarget.value }));
  }, []);

  const handleSelectClient = useCallback((client: User | null | undefined) => {
    if (client) return setEditOrder((prev) => ({ ...prev, client, client_id: client.id }));
    return setEditOrder((prev) => ({ ...prev, client: null, client_id: null }));
  }, []);

  const handleSelectRepresentative = useCallback((representative: User | null | undefined) => {
    if (representative)
      return setEditOrder((prev) => ({ ...prev, delegue: representative, delegue_id: representative.id }));
    return setEditOrder((prev) => ({ ...prev, delegue: null, delegue_id: null }));
  }, []);

  const handleSelectOptions = useCallback((e: SelectTypeOptions | null, property: PropertyNameOptions) => {
    if (e && property === 'affair')
      return setEditOrder((prev) => ({ ...prev, [`${property}`]: e, [`${property}_id`]: e.id }));
    return setEditOrder((prev) => ({ ...prev, affair: null, affair_id: null }));
  }, []);

  const handleDateChange = useCallback((name: string, date: Moment | null) => {
    // setEditOrder((prev) => ({ ...prev, [name]: date?.startOf('day').format('YYYY-MM-DD') }));
    setEditOrder((prev) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  }, []);

  useEffect(() => {
    const getScopes = async () => {
      if (customer?.id) {
        const scopesRetrieved = await ScopeApiService.fetchByCustomerId(customer.id);
        setScopes(scopesRetrieved);
      }
    };
    getScopes();
  }, []);

  useEffect(() => {
    const getCreators = async () => {
      const creatorsFetched = await UserApiService.fetchPossiblesDelegues();
      setCreators(creatorsFetched);
    };
    getCreators();
  }, []);

  // useEffect(() => {
  //   setIsMissingInputs(
  //     (!editOrder.affair_name && !editOrder.name_spec) || moment(editOrder?.end_date).isBefore(editOrder?.start_date)
  //   );
  //   setIsDraftMissingInputs(!editOrder.affair_id && !editOrder.name_spec);
  // }, [editOrder]);

  const isDateValid = (startDate: string | undefined, endDate: string | undefined): boolean => {
    if (!startDate || !endDate) {
      return false;
    }
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    if (Number.isNaN(start.getTime()) || Number.isNaN(end.getTime())) {
      return false;
    }
    return end >= start;
    // const flag = moment(endDate, 'DD/MM/YYYY').isSameOrAfter(moment(startDate, 'DD/MM/YYYY'));
    // console.log('flag: ', flag);
    // console.log('endDate: ', endDate);

    // return moment(endDate).isSameOrAfter(moment(startDate));
    // return moment(endDate, 'DD/MM/YYYY').isSameOrAfter(moment(startDate, 'DD/MM/YYYY'));
  };

  useEffect(() => {
    const missingInputs =
      !editOrder?.affair_id ||
      !editOrder?.name_spec ||
      !isDateValid(editOrder?.start_date, editOrder?.end_date) ||
      selectedScopes.length === 0 ||
      selectedDeliveryManager.length === 0 ||
      selectedConsultants.length === 0 ||
      !editOrder?.client_id;

    //  console.log('missingInputs : ', missingInputs);
    setIsMissingInputs(missingInputs);

    // const hasDraftMissingInputs =
    //   !newOrder?.affair_id &&
    //   (newOrder?.start_date && newOrder?.end_date ? !isDateValid(newOrder?.start_date, newOrder?.end_date) : false);
    const hasDraftMissingInputs =
      !editOrder?.affair_id || !isDateValid(editOrder?.start_date, editOrder?.end_date) || !editOrder?.name_spec;

    //  console.log('hasDraftMissingInputs : ', hasDraftMissingInputs);
    setIsDraftMissingInputs(hasDraftMissingInputs);
  }, [editOrder, selectedScopes, selectedDeliveryManager, selectedConsultants]);

  const handleSubmit = useCallback(
    (actionType: ActionType) => async (event: React.MouseEvent<HTMLButtonElement>) => {
      const handleTMUpdateOrder = initialDiaData?.data?.handleTMUpdateOrder;
      const { ids: accountable_ids, archived: is_accountable_archived } = updateIds(
        selectedDeliveryManager,
        archivedDeliveryManagers
      );
      const { ids: consultant_ids, archived: is_consultant_archived } = updateIds(
        selectedConsultants,
        archivedConsultants
      );

      const { ids: scope_ids, archived: is_scope_archived } = updateIds(selectedScopes, archivedScopes);

      const client_ids = editOrder?.client?.id ? [editOrder?.client?.id] : null;
      const is_client_archived = client_ids !== null ? [false] : [];

      const tmOrderScope: TMOrderScope = {
        id: 0, // This will be generated by the backend
        accountable_ids,
        consultant_ids,
        client_ids,
        scope_ids,
        order_id: editOrder.id ?? 0,
        is_scope_archived,
        is_accountable_archived,
        is_consultant_archived,
        is_client_archived,
      };
      const orderStatus = actionType === 'save_as_draft' ? OrderStatus.MATERIAL_DRAFT : OrderStatus.MATERIAL_PRODUCTION;

      const updatedOrder = {
        ...editOrder,
        tm_order_scope: tmOrderScope,
        tmscopes: selectedScopes,
        tmconsultants: selectedConsultants,
        tmaccountables: selectedDeliveryManager,
        tmclients: editOrder.clients,
        status: orderStatus,
      };

      const result = await handleTMUpdateOrder(updatedOrder, actionType);
      if (result) {
        setEditOrder(result);
        // console.log('Order successfully updated: ', result);
      }
      dispatch(closeDialogAction('timeMaterialEditionOrder'));
    },
    [
      editOrder,
      selectedDeliveryManager,
      archivedDeliveryManagers,
      selectedConsultants,
      archivedConsultants,
      selectedScopes,
      archivedScopes,
      initialDiaData,
      dispatch,
    ]
  );

  const handleSwitchSign = useCallback((value: boolean) => {
    setEditOrder((prev) => ({ ...prev, is_sign_mandatory: value }));
  }, []);

  const handleClientConnectionSwitch = useCallback((value: boolean) => {
    setEditOrder((prev) => ({ ...prev, client_connection: value }));
  }, []);

  useEffect(() => {
    const getClients = async () => {
      if (appState.customer) {
        const res = await makeCall(
          UserApiService.getClientsOnCustomer(appState.customer?.id, { is_archived: 0, size: '-1' }),
          'Failed to retrieve clients'
        );
        setClients(res);
      }
    };

    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer]);

  useEffect(() => {
    const getConsultants = async () => {
      if (appState.customer && appState.customer.id) {
        const res = await makeCall(UserApiService.fetchPossiblesConsultants(), 'Unable to retrieve consultants');
        setConsultants(res);
      }
    };
    getConsultants();
  }, [appState.customer]);

  useEffect(() => {
    const getDeliveryManagers = async () => {
      if (appState.customer && appState.customer.id) {
        const res = await makeCall(
          UserApiService.fetchPossiblesDeliverableTeamLeaders(),
          'Unable to retrieve delivery managers'
        );
        setDeliveryManagers(res);
      }
    };
    getDeliveryManagers();
  }, [appState.customer]);

  const handleScopeAdded = useCallback((newScope: Scope) => {
    const scopeWithArchiveStatus = { ...newScope, is_archived: false };
    setScopes((prevScopes) => [...prevScopes, scopeWithArchiveStatus]);
  }, []);

  const handleScopeDeletionMessage = useCallback(() => {
    setInitialDiaData((prev) => ({
      ...prev,
      data: {
        handleTMUpdateOrder: prev.data?.handleTMUpdateOrder,
      },
    }));
    dispatch(
      openDialogAction({
        name: 'orderActionRestrictionDialog',
        data: {
          message: 'You are unable to perform this action. This scope is already associated with ongoing deliverables.',
        },
      })
    );
  }, [dispatch]);

  const handleDMUserDeletionMessage = useCallback(() => {
    dispatch(
      openDialogAction({
        name: 'orderActionRestrictionDialog',
        data: {
          message:
            'You are unable to perform this action. This delivery manager is already linked with ongoing deliverables.',
        },
      })
    );
  }, [dispatch]);

  const handleConsultantUserDeletionMessage = useCallback(() => {
    dispatch(
      openDialogAction({
        name: 'orderActionRestrictionDialog',
        data: {
          message:
            'You are unable to perform this action. This consultant is already linked with ongoing deliverables.',
        },
      })
    );
  }, [dispatch]);

  const handleScopeArchived = useCallback((updatedScope: Scope) => {
    setScopes((prevScopes) =>
      prevScopes.map((scope) => (scope.id === updatedScope.id ? { ...scope, is_archived: true } : scope))
    );
    setArchivedScopes((prevArchivedScopes) => [...prevArchivedScopes, updatedScope]);
  }, []);

  const handleScopesChange = useCallback((newSelectedScopes: Scope[]) => {
    setSelectedScopes(newSelectedScopes);
  }, []);

  const handleConsultantChange = useCallback((newSelectedConsultants: User[], reason?: string) => {
    setSelectedConsultants(newSelectedConsultants);
  }, []);

  const handleDeliveryManagerChange = useCallback((newSelectedDeliveryManager: User[], reason?: string) => {
    setSelectedDeliveryManager(newSelectedDeliveryManager);
  }, []);

  const handleDeliveryManagerArchived = useCallback((updatedUser: User) => {
    setArchivedDeliveryManagers((prevArchivedDeliveryManager) => [...prevArchivedDeliveryManager, updatedUser]);
  }, []);

  const handleConsultantArchived = useCallback((updatedUser: User) => {
    setArchivedConsultants((prevArchiveConsultant) => [...prevArchiveConsultant, updatedUser]);
  }, []);

  const handleClose = useCallback(() => {
    dispatch(closeDialogAction('timeMaterialEditionOrder'));
  }, [dispatch]);

  const restrictionDialogMessage = dialogData?.data?.message || '';
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        PaperProps={{
          sx: {
            width: '80%', // Adjust the percentage or use a fixed value
            maxWidth: 'none', // This ensures the width you set is used
          },
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ flexGrow: 1, fontSize: '2rem' }}>
              {t('Update Time & Materials Order')}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TimeMaterialOrderForm
            handleChange={handleChange}
            handleSelectClient={handleSelectClient}
            handleSelectRepresentative={handleSelectRepresentative}
            handleSelectOptions={handleSelectOptions}
            handleDateChange={handleDateChange}
            handleSwitchSign={handleSwitchSign}
            handleClientConnectionSwitch={handleClientConnectionSwitch}
            order={editOrder}
            creators={creators}
            clients={clients}
            deliveryManagers={deliveryManagers}
            consultants={consultants}
            scopes={scopes}
            onScopeAdded={handleScopeAdded}
            onScopeArchived={handleScopeArchived}
            handleScopesChange={handleScopesChange}
            selectedScopes={selectedScopes}
            onDeliveryManagerArchived={handleDeliveryManagerArchived}
            onConsultantArchived={handleConsultantArchived}
            handleConsultantChange={handleConsultantChange}
            handleDeliveryManagerChange={handleDeliveryManagerChange}
            selectedConsultants={selectedConsultants}
            selectedDeliveryManager={selectedDeliveryManager}
            availableScopes={availableScopes}
            availableDeliveryManagers={availableDeliveryManagers}
            availableConsultants={availableConsultants}
            onScopeDeletionAttempt={handleScopeDeletionMessage}
            onDMUserDeletionAttempt={handleDMUserDeletionMessage}
            onConsultantUserDeletionAttempt={handleConsultantUserDeletionMessage}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={4} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md={4} lg={2}>
              <Button onClick={handleClose} disabled={false} color="success" variant="contained">
                {isInValidation ? <Loader size={20} className="white-loader" /> : t('Cancel_tm')}
              </Button>
            </Grid>

            <Grid item xs={12} md={2} lg={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item>
                <Button
                  onClick={handleSubmit('save_as_draft')}
                  disabled={
                    !(management.pageHandler.currentTab === OrdersTabState.MATERIAL_DRAFT) || isDraftMissingInputs
                  }
                  color="success"
                  variant="contained"
                  sx={{ width: 150, mr: 2 }}
                >
                  {isInValidation ? <Loader size={20} className="white-loader" /> : t('Save_as_draft_tm')}
                </Button>
              </Grid>

              <Grid item>
                <LoadingButton
                  variant="contained"
                  onClick={handleSubmit('validate')}
                  loading={isUpdateOrderLoading !== 0}
                  startIcon={<Add />}
                  color="success"
                  disabled={isMissingInputs}
                >
                  {t('Validate')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <OrderActionRestrictionDialog message={restrictionDialogMessage} />
    </>
  );
};

export default TnMEditionOrder;
